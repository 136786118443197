<template>
  <b-container>
    <h2>{{ $t('profile.payment_and_subscription.title') }}</h2>

    <div class="sort-navigation mb-5">
      <ul v-if="available_markets.length > 0">
         <li
          v-for="(market, i) in available_markets"
          :key="i"
          :class="has_market_free_subscriptions(market) ? 'free' : ''"
         >
            <a
              :class="{'active': market === get_active_market()}"
              @click="active_market = market"
            >
              {{ market }}
            </a>
        </li>
      </ul>
    </div>

    <b-row class="mt-5 d-flex justify-content-center"
      v-if="active_market === 'Your Packages'">
      <b-col
        md="6"
        lg="4"
        xl="3"
        v-for="manual_subscription in manual_subscriptions"
        :key="manual_subscription.id">
        <manual-subscription-plan-card :manual_subscription="manual_subscription"></manual-subscription-plan-card>
      </b-col>

      <b-col
        md="6"
        lg="4"
        xl="3"
        v-for="bought_subscription in bought_subscriptions"
        :key="bought_subscription.id">
        <subscription-plan-card :subscription_plan="get_plan_by_recurly_id(bought_subscription.recurly_plan.id)"></subscription-plan-card>
      </b-col>
    </b-row>

    <b-row class="mt-5 d-flex justify-content-center" v-else>
      <b-col
        class="mb-0"
        md="6"
        lg="4"
        xl="3"
        v-for="(interval, key) in filtered_time_intervals()"
        :key="key">
          <h4 class="mb-4 text-center font-weight-light">{{ capitalize_first(interval) }}</h4>

          <b-row
            class="d-flex flex-column align-items-center justify-content-center"
            v-for="subscription_plan in subscription_plans_for_interval(interval)"
            :key="subscription_plan.id">
            <subscription-plan-card :subscription_plan="subscription_plan" />
          </b-row>
      </b-col>
    </b-row>

    <h4>{{ $t('profile.payment_and_subscription.products') }}</h4>

    <b-row class="mt-5">
      <b-col
        md="6"
        lg="4"
        v-for="product in published_products"
        v-bind:key="product.id"
      >
        <div class="product">
          <div
            v-if="product.has_discount && !product_bought(product.id)"
            class="discount-badge"
          >
            <span>-{{ product.discount }}%</span>
          </div>

          <div class="image-wrapper">
            <img :src="product.product_image" alt="Product" />
          </div>

          <h5 class="">{{ product.name }}</h5>

          <p v-if="!product_bought(product.id)">
            {{ product.description }}
          </p>

          <div class="details-button-wrapper">
            <a v-if="product.long_description" @click="long_description_popup(product, product.id, 'product')">
              {{ $t('profile.payment_and_subscription.show_details') }}
            </a>
          </div>

          <h6
            class="price"
            v-if="!product_bought(product.id)"
          >
            <span v-if="product.has_discount">
              {{ product.discount_price | currency }}
            </span>

            <span :class="product.has_discount ? 'discount-strikethrough' : ''">
              {{ product.price | currency }}
            </span>

            <br>

            <small>{{ $t('profile.payment_and_subscription.plus_tax')}}</small>
          </h6>

          <base-button
            :aTag="true"
            :href="product.payment_url ? product.payment_url : $t('profile.payment_and_subscription.contact_us_mailto_email', { support_email: env.support_email })"
            target="_blank"
            v-if="!product_bought(product.id)"
          >
            {{ product.payment_url ? $t('profile.payment_and_subscription.buy_now') : $t('profile.payment_and_subscription.contact_us') }}
          </base-button>

          <i
            class="fas fa-check bought"
            v-if="product_bought(product.id)"
          ></i>
        </div>
      </b-col>
    </b-row>

    <base-popup
      v-show="long_description_popup_visibility"
      @close="toggle_long_description_popup"
    >
      <div v-if="popup_type === 'subscription_plan'">
        <div class="header-info">
          <div class="image-wrapper">
            <img
              v-for="market in selected_subscription_plan.markets"
              v-bind:key="market.id"
              :src="require(`@/assets/icon-${market.slug}-big.svg`)"
              :title="market.name"
              alt="Market"
            />

            <img
              v-for="product in selected_subscription_plan.products"
              v-bind:key="product.id"
              :src="product.product_image"
              :title="product.name"
              alt="Product"
            />
          </div>
          <h5 >{{ selected_subscription_plan.name }}</h5>
          <h6 class="price">
            {{ selected_subscription_plan.price | currency }}
            <span>
              per {{ format_interval_unit(selected_subscription_plan.payment_interval_group) }}
            </span>
          </h6>
        </div>
        <div v-html="selected_subscription_plan.long_description"></div>

        <base-button
          :aTag="true"
          :href="subscription_url(selected_subscription_plan)"
          v-if="!subscription_bought(selected_subscription_plan) && (selected_subscription_plan.hosted_page_url || selected_subscription_plan.plan_type === 'free')"
        >
          {{ $t('profile.payment_and_subscription.subscribe') }}
        </base-button>
      </div>

      <div class="product-popup" v-if="popup_type === 'product'">
        <div class="header-info">
          <div class="image-wrapper">
            <img :src="selected_product.product_image" alt="Product" />
          </div>
          <h5>{{ selected_product.name }}</h5>
          <h6 class="price">
           <span v-if="selected_product.has_discount">
              {{ selected_product.discount_price | currency }}
            </span>
            <span :class="selected_product.has_discount ? 'discount-strikethrough' : ''">{{ selected_product.price | currency }}</span>
          </h6>
        </div>
        <div v-html="selected_product.long_description"></div>
        <base-button
          :aTag="true"
          :href="selected_product.payment_url"
          target="_blank"
          v-if="!product_bought(selected_product.id)"
        >
          {{ $t('profile.payment_and_subscription.buy_now') }}
        </base-button>
      </div>
    </base-popup>

    <div class="cancel-reactivate-subscription">
      <base-popup
        v-show="cancel_reactivate_subscription_popup_visibility"
        @close="toggle_cancel_reactivate_subscription_popup"
      >
        <h4>{{ popup_type === 'cancel' ? $t('profile.payment_and_subscription.confirm_cancelation') : $t('profile.payment_and_subscription.confirm_reactivation') }}</h4>
        <div>
          <button
            class="btn btn-sm btn-danger mr-2"
            @click="popup_type === 'cancel' ? cancel_subscription(subscription_id, subscription_type) : reactivate_subscription(subscription_id, subscription_type), toggle_cancel_reactivate_subscription_popup()"
          >
            {{ $t('profile.account.yes') }}
          </button>
          <button
            class="btn btn-sm btn-primary"
            @click="toggle_cancel_reactivate_subscription_popup()"
          >
            {{ $t('profile.account.no') }}
          </button>
        </div>
      </base-popup>
    </div>

    <base-popup v-show="is_open_door" class="text-center" @close="toggle_is_open_door_popup">
      <base-logo-wave class="base-logo-wave mx-auto mb-5" :white="user_theme" width="43px"/>

      <h4>{{ open_door_popup_content[0] }}</h4>
      <p>{{ open_door_popup_content[1] }}</p>
      <div>
        {{ $t("common.click") }} <a @click="open_door_accessed" href="#">{{ $t("common.here") }}</a> {{ $t("common.for") }} {{ $t("common.full") }} {{ $t("common.access") }}.
      </div>
    </base-popup>
  </b-container>
</template>

<script>
/* global Tawk_API */

import BaseButton from '../../components/BaseButton.vue'
import BasePopup from '../../components/BasePopup.vue'
import BaseLogoWave from '../../components/BaseLogoWave.vue'
import { mapState, mapGetters } from 'vuex'
import SubscriptionPlanCard from '../../components/SubscriptionPlanCard.vue'
import ManualSubscriptionPlanCard from '../../components/ManualSubscriptionPlanCard.vue'

export default {
  data () {
    return {
      env: {
        support_email: process.env.VUE_APP_SUPPORT_EMAIL,
        environment: process.env.NODE_ENV,
        api_url: process.env.VUE_APP_API_URL
      },
      long_description_popup_visibility: false,
      cancel_reactivate_subscription_popup_visibility: false,
      selected_subscription_plan: {
        interval_unit: ''
      },
      selected_subscription_plan_index: 0,
      selected_product: {},
      popup_type: '',
      active_market: '',
      time_intervals: ['monthly', 'quarterly', 'semiannually', 'yearly'],
      subscription_markets: ['Combo', 'FX', 'Crypto', 'Stocks', 'Your Packages'],
      total_price: ['0'],
      subscription_type: 'recurly',
      subscription_id: 0,
      is_open_door: false,
      open_door_popup_content: []
    }
  },
  components: {
    ManualSubscriptionPlanCard,
    SubscriptionPlanCard,
    BaseButton,
    BasePopup,
    BaseLogoWave
  },
  computed: {
    ...mapState({
      current_user: state => state.profile.account.current_user,
      products: state => state.products.products,
      markets: state => state.market.markets,
      subscription_plans: state => state.subscription_plans.subscription_plans,
      countries: state => state.countries.countries,
      user_theme: state => state.user_theme.user_theme
    }),
    ...mapGetters({
      userFullName: 'profile/account/userFullName',
      get_country_by_id: 'countries/get_country_by_id'
    }),
    published_subscription_plans () {
      return this.subscription_plans.filter(
        sp => (sp.published === true || this.current_user.subscriptions.some(cus => cus.recurly_plan.id === sp.id))
      ).sort((a, b) => a.order - b.order)
    },
    market_subscription_plans () {
      return this.published_subscription_plans.filter(sp => this.filter_plan(sp, this.active_market))
    },
    available_markets () {
      return this.subscription_markets.filter(market =>
        (market === 'Your Packages' && (this.manual_subscriptions.length > 0 || this.bought_subscriptions.length > 0)) ||
        (this.published_subscription_plans.filter(sp => !this.subscription_bought(sp) && this.filter_plan(sp, market)).length > 0)
      )
    },
    published_products () {
      return this.products.filter(
        p => (p.disabled === false || this.current_user.products.some(cup => cup.id === p.id))
      )
    },
    manual_subscriptions () {
      return this.current_user.subscriptions.filter(s => s.subscription_type === 'manual' || s.subscription_type === 'free')
    },
    bought_subscriptions () {
      return this.current_user.subscriptions.filter(s => s.subscription_type === 'recurly')
    }
  },
  mounted: function () {
    Promise.all([
      this.$store.dispatch('products/loadProducts'),
      this.$store.dispatch('subscription_plans/loadSubscriptionPlans'),
      this.$store.dispatch('countries/loadCountries')
    ])

    const user = this.$auth.user()
    // opens a Open Door popup if user has no subscriptions and has completed profile and has not accessed the Open Door popup before
    if (user.open_door && user.profile_completed && user.subscriptions.length === 0 && user.open_door_accessed === false) {
      this.is_open_door = true
      this.open_door_popup_content[0] = user.open_door_popup_title
      this.open_door_popup_content[1] = user.open_door_popup_text
    }

    setTimeout(function () {
      Tawk_API.showWidget() // eslint-disable-line
    }, 1000)
  },
  beforeRouteLeave (to, from, next) {
    Tawk_API.hideWidget() // eslint-disable-line
    next()
  },
  methods: {
    get_active_market () {
      if (this.active_market === '') {
        this.active_market = this.available_markets[0]
      }
      return this.active_market
    },
    get_plan_by_recurly_id (recurly_id) {
      return this.published_subscription_plans.find(sp => sp.id === recurly_id)
    },
    capitalize_first (string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    filter_plan (plan, market) {
      const markets = plan.markets

      if (market === 'Combo') {
        return markets.length > 1
      } else {
        return markets.length === 1 && markets[0].name === market
      }
    },
    filtered_time_intervals () {
      const intervals = []
      this.time_intervals.forEach(interval => {
        if (this.subscription_plans_for_interval(interval).length > 0) {
          intervals.push(interval)
        }
      })
      return intervals
    },
    subscription_plans_for_interval (interval) {
      return this.market_subscription_plans.filter(sp => sp.payment_interval_group === interval && !this.subscription_bought(sp)).sort((a, b) => a.name !== b.name ? a.name < b.name ? -1 : 1 : 0)
    },
    has_market_free_subscriptions (market) {
      return this.published_subscription_plans.filter(sp => this.filter_plan(sp, market) && sp.plan_type === 'free' && !this.is_subscribed_to_free_plan(sp)).length > 0
    },
    is_subscribed_to_free_plan (plan) {
      let bought = false
      const user_subscriptions = this.current_user.subscriptions.filter(s => s.subscription_type === 'free')

      user_subscriptions.forEach((user_subscription) => {
        if (plan.id === user_subscription.custom_plan_id) {
          bought = true
        }
      })

      return bought
    },
    toggle_long_description_popup () {
      this.long_description_popup_visibility = !this.long_description_popup_visibility
    },
    toggle_is_open_door_popup () {
      this.is_open_door = !this.is_open_door
    },
    toggle_cancel_reactivate_subscription_popup () {
      this.cancel_reactivate_subscription_popup_visibility = !this.cancel_reactivate_subscription_popup_visibility
    },
    cancel_reactivate_subscription_popup (subscription_id, subscription_type = 'recurly', action_type = 'cancel') {
      this.subscription_id = subscription_id
      this.subscription_type = subscription_type
      this.toggle_cancel_reactivate_subscription_popup()
      this.popup_type = action_type
    },
    long_description_popup (subscription_plan, index, type) {
      if (type === 'subscription_plan') {
        this.selected_subscription_plan = subscription_plan
        this.toggle_long_description_popup()
        this.selected_subscription_plan_index = index
        this.popup_type = type
      } else if (type === 'product') {
        this.selected_product = subscription_plan
        this.toggle_long_description_popup()
        this.popup_type = type
      }
    },
    format_interval_unit (interval_unit) {
      return interval_unit.substring(0, interval_unit.length - 2)
    },
    subscription_url (subscription_plan) {
      if (subscription_plan.plan_type === 'free') {
        return `${this.env.api_url}/webhooks/free-subscribe/${subscription_plan.id}/${this.current_user.id}`
      }

      return `${subscription_plan.hosted_page_url}?account_code=${this.env.environment}-subscriber-${this.current_user.id}&first_name=${this.current_user.name}&last_name=${this.current_user.surname}&email=${encodeURIComponent(this.current_user.email)}`
    },
    window_refresh_timeout (timeout = 5000) {
      setTimeout(() => {
        location.reload()
      }, timeout)
    },
    product_bought (product_id) {
      return this.current_user.products.filter(p => p.id === product_id).length > 0
    },
    subscription_bought (subscription_plan) {
      let bought = false

      if (subscription_plan.plan_type === 'free') {
        const user_subscriptions = this.current_user.subscriptions.filter(s => s.subscription_type === 'free')

        user_subscriptions.forEach((user_subscription) => {
          if (subscription_plan.id === user_subscription.custom_plan_id) {
            bought = true
          }
        })
      } else {
        const recurly_subscriptions = this.current_user.subscriptions.filter(s => s.subscription_type === 'recurly')

        recurly_subscriptions.forEach((recurly_subscription) => {
          if (subscription_plan.id === recurly_subscription.recurly_plan.id) {
            bought = true
          }
        })
      }

      return bought
    },
    cancel_subscription (subscription_id, subscription_type = 'recurly') {
      if (subscription_type === 'recurly') {
        subscription_id = this.current_user.subscriptions.find(s => s.recurly_plan.id === subscription_id).id
      }

      this.$store.dispatch('subscription_plans/cancelSubscription', {
        current_user_id: this.current_user.id,
        subscription_id: subscription_id,
        subscription_type: subscription_type
      }).then((response) => {
        if (response.data.status) {
          this.$notify({
            type: 'success',
            text: this.$t('profile.payment_and_subscription.was_canceled')
          })

          this.window_refresh_timeout()
        } else {
          this.$notify({
            type: 'error',
            text: this.$t('profile.payment_and_subscription.cancelation_error')
          })
        }
      })
    },
    reactivate_subscription (subscription_id, subscription_type = 'recurly') {
      if (subscription_type === 'recurly') {
        subscription_id = this.current_user.subscriptions.find(s => s.recurly_plan.id === subscription_id).id
      }

      this.$store.dispatch('subscription_plans/reactivateSubscription', {
        current_user_id: this.current_user.id,
        subscription_id: subscription_id,
        subscription_type: subscription_type
      }).then((response) => {
        if (response.data.status) {
          this.$notify({
            type: 'success',
            text: this.$t('profile.payment_and_subscription.was_reactivated')
          })

          this.window_refresh_timeout()
        } else {
          this.$notify({
            type: 'error',
            text: this.$t('profile.payment_and_subscription.reactivation_error')
          })
        }
      })
    },
    open_door_accessed () {
      this.$store.dispatch('subscription_plans/openDoorAccessed', {
        current_user_id: this.current_user.id
      }).then((response) => {
        this.current_user.open_door_accessed = true
        this.$router.push('/forex/charts')
      })
    }
  }
}
</script>

<style scoped lang="scss">
.sort-navigation {
  ul {
    list-style-type: none;
    padding: 0;
    text-align: center;
  }

  li {
    display: inline-block;
    position: relative;
    margin-right: 5px;

    &:last-child {
      margin-right: 0;
    }

    &.free {
      position: relative;
      &:after {
        content: '';
        background-image: url(../../assets/free.jpg);
        background-size: 40px 16px;
        background-repeat: no-repeat;
        width: 40px;
        height: 16px;
        position: absolute;
        top: 0;
        right: 0;
        margin-top: 12px;
        margin-right: 2px;
      }
    }
  }

  a {
    padding: 23px 45px;
    margin-bottom: 0;
    font-size: 14px;
    color: var(--charcoal-grey-white);
    text-transform: capitalize;
    border: none;
    display: block;

    span {
      display: block;
      color: var(--cloudy-blue);
      line-height: 1.3;
      font-weight: 300;
      font-size: 14px;
    }

    &:hover {
      text-decoration: none;
      cursor: pointer;
    }

    &:after {
      transition: all 0.35s ease;
      background: transparent;
      content: '';
      display: block;
      height: 3px;
      width: 0;
      position: absolute;
      bottom: 0;
      left: 0;
    }

    &:hover {
      &:after {
        background: var(--muted-blue-dark-sky-blue);
        width: 100%;
      }
    }

    &.active{
      color: var(--muted-blue-dark-sky-blue);

      &:after {
        background: var(--muted-blue-dark-sky-blue);
        width: 100%;
      }
    }
  }

  @media (max-width: 575px) {
    li {
      display: block;
      width: 100%;
      text-align: center;
    }
  }
}

.col-lg-4 {
  margin-bottom: 45px;
}

::v-deep .plan {
  width: 90%;
  border: solid 1px var(--pale-grey-battleship-grey-three);
  padding: 38px;
  position: relative;
  animation: fadeIn ease 1s;
  height: 100%;
  display: flex;
  flex-direction: column;

  h5 {
    display: inline-block;
    word-break: break-word;
  }

  a {
    display: block;
    font-size: 13px;
    color: var(--blueberry);
    font-weight: 300;

    &:not([href]):not([tabindex]) {
      cursor: pointer;
    }
  }

  .btn-primary {
    color: var(--white);
    font-weight: 300;
    bottom: 0;
  }

  .validation {
    p {
      margin-top: 35px;
      margin-bottom: 20px;
    }

    #cancel-subscription {
      color: var(--dark-coral);
      font-size: 12px;
      background-color: var(--very-light-light-black);

      &:hover {
        opacity: .9;
      }
    }
  }
}

.product {
  border: solid 1px var(--pale-grey-battleship-grey-three);
  padding: 55px;
  position: relative;
  animation: fadeIn ease 1s;
  height: 100%;
  display: flex;
  flex-direction: column;

  a {
    display: block;
    font-size: 13px;
    color: var(--blueberry);
    font-weight: 300;

    &:not([href]):not([tabindex]) {
      cursor: pointer;
    }
  }

  .price {
    margin-top: auto;
  }

  .details-button-wrapper {
    margin-bottom: 35px;
  }

  .btn-primary {
    color: var(--white);
    font-weight: 300;
    bottom: 0;
  }

  .validation {
    p {
      margin-top: 35px;
      margin-bottom: 20px;
    }

    #cancel-subscription {
      color: var(--dark-coral);
      font-size: 12px;
      background-color: var(--very-light-light-black);

      &:hover {
        opacity: .9;
      }
    }
  }

  .image-wrapper img {
    margin-bottom: 45px;
  }

  .price {
    span {
      font-size: 24px;
      line-height: 1;
    }
  }
}

h2 {
  font-size: 26px;
  text-align: center;
  color: var(--charcoal-grey-white);
  font-weight: normal;
  margin: 45px 0 56px 0;
}

h4 {
  font-size: 18px;
  font-weight: 400;
  color: var(--dark-grey-white);
  margin-bottom: 14px;
  margin-left: -5px;
}

::v-deep h5 {
  font-size: 25px;
  font-weight: 300;
  color: var(--black-white);
  margin-bottom: 25px;
}

::v-deep p {
  font-size: 15px;
  font-weight: 300;
  font-family: 'Athelas', arial, sans-serif;
  line-height: 1.53;
  margin-bottom: 5px;
}

.price {
  font-family: 'Montserrat', 'Arial', sans-serif;
  font-weight: 300;
  margin-bottom: 20px;
  margin-top: 35px;
  font-size: 24px;
  line-height: 15px;

  span {
    font-size: 14px;
  }

  small {
    font-size: 14px;
    color: var(--cloudy-blue);
    font-family: 'Heebo', arial, sans-serif;
  }
}

::v-deep .image-wrapper {
  position: relative;
  display: inline-block;

  img {
    margin-bottom: 60px;
    width: 50px;

    &:not(:first-child) {
      position: absolute;
      left: 25px;
      top: 25px;
    }

    &:nth-child(3) {
      left: 50px;
      top: 50px;
    }
  }
}

::v-deep .btn {
  max-width: 150px;
  min-width: 150px;
}

::v-deep .discount-badge {
  position: absolute;
  top: 8px;
  right: 0;

  span {
    font-size: 20px;
    color: var(--white);
    background-color: var(--tealish-two);
    padding: 2px 5px;
  }
}

.cancel-subscription {
  margin-top: 55px;
  padding-bottom: 140px;

  h4 {
    margin-left: 0;
  }

  p {
    font-family: 'Athelas', arial, sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.63;
    letter-spacing: 0.1px;
    margin-bottom: 0;
    min-height: inherit;
    color: var(--charcoal-grey-white);
  }

  a {
    @include basic-transition;
    cursor: pointer;
    text-decoration: underline;

    &:hover {
      opacity: .8;
    }
  }
}

.discount-strikethrough {
  position: relative;
  font-size: 13px;
  font-weight: 300;
  color: var(--cloudy-blue);
  text-decoration: line-through;
}

::v-deep .popup-content {
  padding: 90px;
  color: var(--charcoal-grey-white);

  .product-popup {
    .header-info h6 {
      line-height: 50px;
    }

    .price {
      span {
        font-size: 24px;
      }
    }
  }

  h3 {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 35px;
  }

  p {
    font-family: 'Athelas', arial, sans-serif;
    line-height: 1.6;
    letter-spacing: .1px;
    margin-bottom: 35px;

    br {
      display: none;
    }
  }

  ul {
    border: solid 1px var(--very-light-pink-battleship-grey);
    padding: 50px;
    list-style-type: none;
    margin-bottom: 60px;

    li {
      margin-bottom: 35px;
      font-size: 14px;
      font-weight: 300;
      position: relative;

      &:last-child {
        margin-bottom: 0;
      }

      &:after {
        content: '\f00c';
        font-weight: 900;
        font-family: "Font Awesome 5 Free";
        color: var(--tealish-two);
        position: absolute;
        right: 0;
        font-size: 15px;
      }
    }
  }

  .btn-primary {
    max-width: 200px;
    width: 200px;
    margin: 30px auto 0;
  }

  .header-info {
    margin-bottom: 60px;

    img {
      margin-bottom: 0;
    }

    h5 {
      display: inline-block;
      vertical-align: middle;
      margin-bottom: 0;
      margin-left: 35px;
    }

    h6 {
      float: right;
      margin-bottom: 0;
      margin-top: 0;
      line-height: 48px;
    }
  }

  @media (min-width: 576px) {
    max-width: 960px;
  }

  @media (max-width: 960px) {
    margin: 0;
  }

  @media (max-width: 768px) {
    padding: 60px;
  }

  @media (max-width: 575px) {
    padding: 60px 15px;

    &.product-popup-content {
      .header-info .image-wrapper {
        margin-bottom: 30px;
      }
    }

    .header-info {
      text-align: center;

      h5 {
        display: block;
        margin-left: 0;
      }

      h6 {
        float: none;
      }

      .image-wrapper {
        margin-bottom: 60px;
      }
    }
  }
}

.cancel-reactivate-subscription {
  ::v-deep .popup-wrapper {
    height: 100%;

    .popup-content {
      text-align: center;
      padding: 30px;
      max-width: 400px;
      margin: 0 auto;
      top: 50%;
      transform: translateY(-50%);

      .btn,
      .btn-primary {
        max-width: initial;
        min-width: initial;
        width: auto;
        margin: auto;
      }
    }

    .close-popup {
      display: none;
    }
  }

  @media (max-width: 575.98px) {
    ::v-deep .base-popup {
      padding: 0 15px;

      .popup-content {
        padding: 20px;
      }
    }
  }
}
</style>
